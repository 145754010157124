import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';  // Your global CSS file
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';  // Import MUI theme utilities
import CssBaseline from '@mui/material/CssBaseline';  // For resetting baseline CSS

// Create the MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',  // Customize your primary color here
    },
    secondary: {
      main: '#f50057',  // Customize your secondary color here
    },
  },
  typography: {
    fontFamily: [
      '-apple-system', 
      'BlinkMacSystemFont', 
      '"Segoe UI"', 
      'Roboto', 
      '"Helvetica Neue"', 
      'Arial', 
      'sans-serif'
    ].join(','),
  },
});

// React 18 root API initialization
const root = ReactDOM.createRoot(document.getElementById('root')); 
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>  {/* Wrap App in MUI's ThemeProvider */}
      <CssBaseline />  {/* Adds consistent global CSS resets */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// Performance logging (optional)
reportWebVitals();
