// GHGEmissionsService.js
import { makeRequest, safeParseEmissions, safeParseDateToISO } from './GHGUtils';

export const fetchEmissionsData = async () => {
  try {
    const currentYear = new Date().getFullYear();
    const startDate = new Date(currentYear - 5, 0, 1);
    const endDate = new Date(currentYear, 11, 31);

    const queryParams = new URLSearchParams({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    });

    const endpoints = {
      // Scope 1
      stationaryCombustion: '/scope1/stationary-combustion',
      mobileCombustion: '/scope1/mobile-combustion',
      fugitiveEmissions: '/scope1/fugitive-emissions',
      
      // Scope 2
      purchasedElectricity: '/scope2/purchased-electricity',
      purchasedHeating: '/scope2/purchased-heating',
      
      // Scope 3
      businessTravelLandDB: '/scope3/business-travel-land-db',
      employeeCommutingLandDB: '/scope3/employee-commuting-land-db',
      upstreamLeasedAssets: '/scope3/upstream-leased-assets-AS-S1-SC',
      scope3InitialAssessment: '/scope3-initial-assessment'
    };

    const responses = await Promise.all(
      Object.entries(endpoints).map(async ([key, endpoint]) => {
        try {
          const response = await makeRequest(`${endpoint}?${queryParams}`);
          const entries = Array.isArray(response) ? response : 
                         response?.entries ? response.entries : [];
          return { key, data: entries, error: null };
        } catch (error) {
          console.error(`Error fetching ${key}:`, error);
          return { key, data: [], error: error.message };
        }
      })
    );

    const newData = {
      scope1: {
        stationaryCombustion: { data: [], loading: false, error: null },
        mobileCombustion: { data: [], loading: false, error: null },
        fugitiveEmissions: { data: [], loading: false, error: null }
      },
      scope2: {
        purchasedElectricity: { data: [], loading: false, error: null },
        purchasedHeating: { data: [], loading: false, error: null }
      },
      scope3: {
        businessTravelLandDB: { data: [], loading: false, error: null },
        employeeCommutingLandDB: { data: [], loading: false, error: null },
        upstreamLeasedAssets: { data: [], loading: false, error: null },
        initialAssessment: { data: [], loading: false, error: null }
      }
    };

    let scope1Total = 0;
    let scope2Total = 0;
    let scope3Total = 0;

    // Process responses and categorize data
    responses.forEach(({ key, data, error }) => {
      if (!Array.isArray(data)) {
        console.error(`Invalid data format for ${key}:`, data);
        return;
      }

      const processedData = data.map(entry => ({
        ...entry,
        co2e_emissions: safeParseEmissions(entry.co2e_emissions),
        startDate: safeParseDateToISO(entry.startDate)
      }));

      const emissions = processedData.reduce((sum, entry) => 
        sum + safeParseEmissions(entry.co2e_emissions), 0);

      // Enhanced scope categorization logic
      if (key === 'scope3InitialAssessment') {
        scope3Total += emissions;
        newData.scope3.initialAssessment = { data: processedData, loading: false, error };
      } else if (key === 'businessTravelLandDB' || key === 'employeeCommutingLandDB' || key === 'upstreamLeasedAssets') {
        scope3Total += emissions;
        newData.scope3[key] = { data: processedData, loading: false, error };
      } else if (key === 'purchasedElectricity' || key === 'purchasedHeating') {
        scope2Total += emissions;
        newData.scope2[key] = { data: processedData, loading: false, error };
      } else {
        scope1Total += emissions;
        newData.scope1[key] = { data: processedData, loading: false, error };
      }
    });

    return {
      emissionsData: newData,
      totalEmissions: {
        scope1Total,
        scope2Total,
        scope3Total,
        grandTotal: scope1Total + scope2Total + scope3Total
      }
    };
  } catch (error) {
    console.error('Error in fetchEmissionsData:', error);
    throw error;
  }
};

export const updateTotalEmissions = (emissionsData) => {
  // Calculate totals for each scope with proper error handling
  const calculateScopeTotal = (scopeData) => {
    try {
      return Object.values(scopeData).reduce((total, source) => {
        if (!source || !Array.isArray(source.data)) return total;
        return total + source.data.reduce(
          (sum, entry) => sum + safeParseEmissions(entry.co2e_emissions), 
          0
        );
      }, 0);
    } catch (error) {
      console.error('Error calculating scope total:', error);
      return 0;
    }
  };

  const scope1Total = calculateScopeTotal(emissionsData.scope1);
  const scope2Total = calculateScopeTotal(emissionsData.scope2);
  const scope3Total = calculateScopeTotal(emissionsData.scope3);

  return {
    scope1Total,
    scope2Total,
    scope3Total,
    grandTotal: scope1Total + scope2Total + scope3Total
  };
};

// Add helper function to validate emissions data structure
export const validateEmissionsData = (emissionsData) => {
  if (!emissionsData) return false;
  
  const requiredScopes = ['scope1', 'scope2', 'scope3'];
  return requiredScopes.every(scope => {
    return emissionsData[scope] && typeof emissionsData[scope] === 'object';
  });
};

// Add function to get emissions by year
export const getEmissionsByYear = (emissionsData, year) => {
  const totals = { scope1: 0, scope2: 0, scope3: 0, total: 0 };
  
  try {
    Object.entries(emissionsData).forEach(([scope, sources]) => {
      Object.values(sources).forEach(source => {
        if (!Array.isArray(source.data)) return;
        
        source.data.forEach(entry => {
          const entryYear = new Date(entry.startDate).getFullYear();
          if (entryYear === year) {
            const emissions = safeParseEmissions(entry.co2e_emissions);
            totals[scope] += emissions;
            totals.total += emissions;
          }
        });
      });
    });
  } catch (error) {
    console.error('Error calculating emissions by year:', error);
  }
  
  return totals;
};