// UnifiedGHGContextPart3.js - Utility Functions
export const safeParseNumber = (value) => {
  if (typeof value === 'number') return value;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  }
  return 0;
};

export const calculateTotals = (emissionsData) => {
  const getTotalForScope = (scope) => {
    return Object.values(scope)
      .reduce((total, { data }) => 
        total + data.reduce((sum, entry) => sum + safeParseNumber(entry.co2e_emissions), 0)
      , 0);
  };

  const scope1Total = getTotalForScope(emissionsData.scope1);
  const scope2Total = getTotalForScope(emissionsData.scope2);
  const scope3Total = getTotalForScope(emissionsData.scope3);

  return {
    scope1Total,
    scope2Total,
    scope3Total,
    totalEmissions: scope1Total + scope2Total + scope3Total
  };
};

export const processTimeSeriesData = (emissionsData, baseYearData) => {
  const monthlyData = new Map();

  const ensureMonthBucket = (date) => {
    const monthKey = new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short'
    });
    if (!monthlyData.has(monthKey)) {
      monthlyData.set(monthKey, {
        date: monthKey,
        scope1: 0,
        scope2: 0,
        scope3: 0,
        total: 0,
        baseYearValue: baseYearData.current?.total ? baseYearData.current.total / 12 : 0
      });
    }
    return monthKey;
  };

  const processScope = (scopeData, scopeName) => {
    Object.values(scopeData).forEach(({ data }) => {
      data.forEach(entry => {
        if (!entry.startDate) return;
        const monthKey = ensureMonthBucket(entry.startDate);
        const bucket = monthlyData.get(monthKey);
        const emissions = safeParseNumber(entry.co2e_emissions);
        bucket[scopeName] += emissions;
        bucket.total += emissions;
      });
    });
  };

  // Process each scope
  processScope(emissionsData.scope1, 'scope1');
  processScope(emissionsData.scope2, 'scope2');
  processScope(emissionsData.scope3, 'scope3');

  return Array.from(monthlyData.values())
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map(data => ({
      ...data,
      scope1: Number(data.scope1.toFixed(2)),
      scope2: Number(data.scope2.toFixed(2)),
      scope3: Number(data.scope3.toFixed(2)),
      total: Number(data.total.toFixed(2)),
      baseYearValue: Number((baseYearData.current?.total / 12).toFixed(2))
    }));
};