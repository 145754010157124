// client/src/context/GHGContext/GHGUtils.js
import apiClient from '../../api/axiosConfig';

// API Base URL from environment variables
export const API_BASE_URL = process.env.REACT_APP_API_URL;

// Safely parse emissions values
export const safeParseEmissions = (value) => {
  if (typeof value === 'number') return value;
  if (typeof value === 'string') {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  }
  return 0;
};

// Safely parse dates to ISO format
export const safeParseDateToISO = (date) => {
  try {
    return new Date(date).toISOString();
  } catch (error) {
    console.error('Date parsing error:', error);
    return new Date().toISOString();
  }
};

// Check if token is valid and not expired
export const isTokenValid = (token) => {
  try {
    if (!token) return false;
    
    // Parse token payload
    const payload = JSON.parse(atob(token.split('.')[1]));
    
    // Check if token has expiration and is not expired
    if (payload.exp) {
      return payload.exp * 1000 > Date.now();
    }
    
    return false;
  } catch (error) {
    console.error('Token validation error:', error);
    return false;
  }
};

// Enhanced makeRequest function with better error handling
export const makeRequest = async (endpoint, options = {}) => {
  try {
    // Remove leading slash if present to avoid double slashes
    const cleanEndpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;

    const config = {
      ...options,
      method: options.method || 'GET',
      data: options.body ? JSON.parse(options.body) : undefined,
      headers: {
        ...options.headers
      }
    };

    const response = await apiClient(cleanEndpoint, config);
    return response;
  } catch (error) {
    console.error(`API Request Error (${endpoint}):`, error);
    
    // Handle specific error cases
    if (error.response) {
      // Server responded with error status
      const status = error.response.status;
      const errorMessage = error.response.data?.message || error.message;

      if (status === 401 || status === 403) {
        localStorage.removeItem('token');
        throw new Error('Authentication required. Please log in.');
      }

      throw new Error(errorMessage);
    } else if (error.request) {
      // Request made but no response received
      throw new Error('Network error. Please check your connection.');
    }
    
    // Something else went wrong
    throw error;
  }
};

// Get initial states for the application
export const getInitialStates = () => ({
  emissionsData: {
    scope1: {
      stationaryCombustion: { data: [], loading: false, error: null },
      mobileCombustion: { data: [], loading: false, error: null },
      fugitiveEmissions: { data: [], loading: false, error: null }
    },
    scope2: {
      purchasedElectricity: { data: [], loading: false, error: null },
      purchasedHeating: { data: [], loading: false, error: null }
    },
    scope3: {
      businessTravelLandDB: { data: [], loading: false, error: null },
      employeeCommutingLandDB: { data: [], loading: false, error: null },
      upstreamLeasedAssets: { data: [], loading: false, error: null },
      initialAssessment: { data: [], loading: false, error: null }
    }
  },
  totalEmissions: {
    scope1Total: 0,
    scope2Total: 0,
    scope3Total: 0,
    grandTotal: 0
  },
  dateRange: {
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date(),
    period: 'ytd'
  }
});

// Helper function to check if a value is empty
export const isEmpty = (value) => {
  if (value === null || value === undefined) return true;
  if (typeof value === 'string') return value.trim() === '';
  if (Array.isArray(value)) return value.length === 0;
  if (typeof value === 'object') return Object.keys(value).length === 0;
  return false;
};

// Helper function to format dates consistently
export const formatDate = (date, format = 'default') => {
  try {
    const d = new Date(date);
    if (isNaN(d.getTime())) throw new Error('Invalid Date');

    switch (format) {
      case 'short':
        return d.toLocaleDateString('en-US', {
          month: 'short',
          year: 'numeric'
        });
      case 'full':
        return d.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      case 'iso':
        return d.toISOString();
      case 'default':
      default:
        return d.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
    }
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Invalid Date';
  }
};

// Helper function to calculate percentage change
export const calculatePercentageChange = (current, previous) => {
  if (!previous || previous === 0) return null;
  return ((current - previous) / previous) * 100;
};

// Helper function to validate API response structure
export const validateAPIResponse = (response) => {
  if (!response) return false;
  if (typeof response !== 'object') return false;
  if (Array.isArray(response)) {
    return response.every(item => 
      typeof item === 'object' && 
      'co2e_emissions' in item && 
      'startDate' in item
    );
  }
  return 'entries' in response || 'data' in response;
};

// Helper function to handle API errors consistently
export const handleAPIError = (error) => {
  // Log the error
  console.error('API Error:', error);

  // Handle authentication errors
  if (error.message?.includes('Authentication required')) {
    localStorage.removeItem('token');
    return {
      error: 'Authentication required. Please log in.',
      requiresAuth: true
    };
  }

  // Handle network errors
  if (error.message?.includes('Network error')) {
    return {
      error: 'Network error. Please check your connection.',
      isNetworkError: true
    };
  }

  // Handle validation errors
  if (error.message?.includes('Validation failed')) {
    return {
      error: error.message || 'Invalid data provided.',
      isValidationError: true
    };
  }

  // Handle other errors
  return {
    error: error.message || 'An unexpected error occurred.',
    isUnexpected: true
  };
};

// Format numbers with appropriate units
export const formatNumber = (number, options = {}) => {
  try {
    const {
      decimals = 2,
      unit = '',
      prefix = '',
      compact = false
    } = options;

    if (typeof number !== 'number' || isNaN(number)) {
      return 'N/A';
    }

    let formattedNumber;
    if (compact) {
      formattedNumber = new Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: decimals
      }).format(number);
    } else {
      formattedNumber = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals
      }).format(number);
    }

    return `${prefix}${formattedNumber}${unit ? ` ${unit}` : ''}`;
  } catch (error) {
    console.error('Number formatting error:', error);
    return 'Error';
  }
};

// Helper function to group data by date periods
export const groupDataByPeriod = (data, period = 'month') => {
  try {
    const grouped = data.reduce((acc, item) => {
      const date = new Date(item.startDate);
      let key;

      switch (period) {
        case 'year':
          key = date.getFullYear().toString();
          break;
        case 'quarter':
          key = `${date.getFullYear()}-Q${Math.floor(date.getMonth() / 3) + 1}`;
          break;
        case 'month':
        default:
          key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      }

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    return Object.entries(grouped).map(([period, items]) => ({
      period,
      items,
      totalEmissions: items.reduce((sum, item) => sum + safeParseEmissions(item.co2e_emissions), 0)
    }));
  } catch (error) {
    console.error('Error grouping data:', error);
    return [];
  }
};

export default {
  API_BASE_URL,
  safeParseEmissions,
  safeParseDateToISO,
  isTokenValid,
  makeRequest,
  getInitialStates,
  isEmpty,
  formatDate,
  calculatePercentageChange,
  validateAPIResponse,
  handleAPIError,
  formatNumber,
  groupDataByPeriod
};