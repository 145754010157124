import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';

import {
  API_BASE_URL,
  CACHE_DURATION,
  MIN_REFRESH_INTERVAL,
  MAX_RETRIES,
  RETRY_DELAY,
  ENDPOINTS,
  initialEmissionsData,
  initialBaseYearData,
  getInitialDateRange
} from './UnifiedGHGContext/UnifiedGHGContextPart2';

import {
  safeParseNumber,
  calculateTotals,
  processTimeSeriesData
} from './UnifiedGHGContext/UnifiedGHGContextPart3';

const UnifiedGHGContext = createContext(null);

export const useUnifiedGHG = () => {
  const context = useContext(UnifiedGHGContext);
  if (!context) {
    throw new Error('useUnifiedGHG must be used within a UnifiedGHGProvider');
  }
  return context;
};

export const UnifiedGHGProvider = ({ children }) => {
  const mountedRef = useRef(false);
  const fetchInProgress = useRef(false);
  const lastFetchRef = useRef(null);
  const initializationAttempted = useRef(false);
  const authCheckInterval = useRef(null);
  const dataCache = useRef({});
  const baseYearFetchTimeout = useRef(null);
  
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [emissionsData, setEmissionsData] = useState(initialEmissionsData);
  const [baseYearData, setBaseYearData] = useState(initialBaseYearData);
  const [dateRange, setDateRange] = useState(getInitialDateRange);
  const [earliestDate, setEarliestDate] = useState(null);
  const [latestDate, setLatestDate] = useState(null);

  const clearCache = useCallback(() => {
    dataCache.current = {};
  }, []);

  const debouncedSetLoading = useCallback(
    debounce((value) => {
      if (mountedRef.current) {
        setLoading(value);
      }
    }, 300),
    []
  );

  const shouldRefreshData = useCallback(() => {
    const now = Date.now();
    if (fetchInProgress.current) return false;
    if (!lastFetchRef.current) return true;
    if (now - lastFetchRef.current < MIN_REFRESH_INTERVAL) return false;
    return now - lastFetchRef.current > CACHE_DURATION;
  }, []);

  const makeRequest = useCallback(async (endpoint, options = {}) => {
    let attempt = 0;
    
    while (attempt < MAX_RETRIES) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication required');
        }

        const skipCache = endpoint === '/base-year' || options.skipCache;
        const cacheKey = `${endpoint}${options.body ? JSON.stringify(options.body) : ''}`;
        
        if (!skipCache) {
          const cachedData = dataCache.current[cacheKey];
          if (cachedData && Date.now() - cachedData.timestamp < CACHE_DURATION) {
            return cachedData.data;
          }
        }

        const response = await fetch(`${API_BASE_URL}${endpoint}`, {
          ...options,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...options.headers
          }
        });

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error('Authentication required');
          }
          if (response.status === 404 && options.suppressNotFound) {
            return null;
          }
          throw new Error(`Request failed: ${response.statusText}`);
        }

        const data = await response.json();
        
        if (!skipCache) {
          dataCache.current[cacheKey] = {
            data,
            timestamp: Date.now()
          };
        }
        
        return data;

      } catch (error) {
        attempt++;
        if (attempt === MAX_RETRIES || error.message === 'Authentication required') {
          throw error;
        }
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * attempt));
      }
    }
  }, []);

  const findDateBoundaries = useCallback((data) => {
    let earliest = null;
    let latest = null;

    const processEntry = (entry) => {
      if (!entry?.startDate) return;
      const date = new Date(entry.startDate);
      if (!earliest || date < earliest) earliest = date;
      if (!latest || date > latest) latest = date;
    };

    Object.values(data).forEach(scope => {
      Object.values(scope).forEach(category => {
        if (Array.isArray(category.data)) {
          category.data.forEach(processEntry);
        }
      });
    });

    return { earliest, latest };
  }, []);

  const batchFetchEmissionsData = useCallback(async () => {
    if (!mountedRef.current || fetchInProgress.current) return;

    try {
      fetchInProgress.current = true;

      const requests = Object.entries(ENDPOINTS).flatMap(([scope, endpoints]) =>
        Object.entries(endpoints).map(([key, endpoint]) => ({
          scope,
          key,
          endpoint,
        }))
      );

      const results = await Promise.all(
        requests.map(({ scope, key, endpoint }) =>
          makeRequest(endpoint)
            .then(data => ({ scope, key, data, error: null }))
            .catch(error => {
              if (error.message.includes('404') && !initialized) {
                return { scope, key, data: [], error: null };
              }
              return { scope, key, data: null, error };
            })
        )
      );

      if (!mountedRef.current) return;

      const newData = { ...initialEmissionsData };
      results.forEach(({ scope, key, data, error }) => {
        if (!newData[scope]) newData[scope] = {};
        newData[scope][key] = {
          data: error ? [] : (Array.isArray(data) ? data : data?.entries || []),
          lastUpdated: new Date(),
          loading: false,
          error: error?.message
        };
      });

      const { earliest, latest } = findDateBoundaries(newData);
      if (earliest) setEarliestDate(earliest);
      if (latest) setLatestDate(latest);

      setEmissionsData(newData);

    } finally {
      if (mountedRef.current) {
        fetchInProgress.current = false;
      }
    }
  }, [makeRequest, initialized, findDateBoundaries]);

  const fetchBaseYearData = useCallback(async (skipCache = false) => {
    try {
      if (baseYearFetchTimeout.current) {
        clearTimeout(baseYearFetchTimeout.current);
      }

      const data = await makeRequest('/base-year', { 
        suppressNotFound: true,
        skipCache 
      });
      
      if (!mountedRef.current) return;

      if (data && data.activeBaseYear) {
        const baseYear = data.activeBaseYear;
        const totals = calculateTotals(emissionsData);
        const scope1Total = safeParseNumber(baseYear.scope1_total);
        const scope2Total = safeParseNumber(baseYear.scope2_total);
        const scope3Total = safeParseNumber(baseYear.scope3_total);
        const totalEmissions = baseYear.total_emissions;

        const changePercentage = totalEmissions > 0 ? 
          ((totals.totalEmissions - totalEmissions) / totalEmissions) * 100 : 0;

        setBaseYearData(prev => ({
          ...prev,
          current: {
            scope1_total: scope1Total,
            scope2_total: scope2Total,
            scope3_total: scope3Total,
            totalEmissions,
            year: baseYear.year.toString(),
            change: {
              value: Math.abs(changePercentage).toFixed(1),
              isIncrease: changePercentage > 0
            },
            total: totalEmissions
          },
          loading: false,
          error: null,
          availableYears: data.availableYears || []
        }));

        baseYearFetchTimeout.current = setTimeout(() => {
          if (mountedRef.current) {
            fetchBaseYearData(true);
          }
        }, 30000);
      }
    } catch (error) {
      console.error('Error fetching base year data:', error);
      if (mountedRef.current) {
        setBaseYearData(prev => ({
          ...prev,
          loading: false,
          error: error.message
        }));
      }
    }
  }, [makeRequest, emissionsData]);

  const handleDateRangeChange = useCallback((newRange) => {
    setDateRange(newRange);
  }, []);

  const refreshData = useCallback(async (force = false) => {
    if (!force && !shouldRefreshData()) return;
  
    try {
      debouncedSetLoading(true);
      lastFetchRef.current = Date.now();
  
      if (!initialized) {
        await batchFetchEmissionsData();
        await fetchBaseYearData(true);
        setInitialized(true);
      } else {
        await Promise.all([
          batchFetchEmissionsData(),
          fetchBaseYearData(true)
        ]);
      }

      clearCache();
    } catch (error) {
      if (mountedRef.current) {
        setError(error.message);
      }
    } finally {
      if (mountedRef.current) {
        debouncedSetLoading(false);
      }
    }
  }, [
    shouldRefreshData,
    initialized,
    debouncedSetLoading,
    batchFetchEmissionsData,
    fetchBaseYearData,
    clearCache
  ]);

  useEffect(() => {
    mountedRef.current = true;

    const checkAuthAndInitialize = async () => {
      const token = localStorage.getItem('token');
      if (token && !initializationAttempted.current) {
        initializationAttempted.current = true;
        try {
          await refreshData(true);
        } catch (error) {
          if (mountedRef.current) {
            setError(error.message);
          }
        }
      }
    };

    checkAuthAndInitialize();

    return () => {
      mountedRef.current = false;
      if (baseYearFetchTimeout.current) {
        clearTimeout(baseYearFetchTimeout.current);
      }
      if (authCheckInterval.current) {
        clearInterval(authCheckInterval.current);
      }
    };
  }, [refreshData]);

  const contextValue = {
    emissionsData,
    baseYearData,
    dateRange,
    loading,
    error,
    initialized,
    earliestDate,
    latestDate,
    setDateRange: handleDateRangeChange,
    refreshData,
    calculateTotals: useCallback(() => calculateTotals(emissionsData), [emissionsData]),
    getTimeSeriesData: useCallback(() => processTimeSeriesData(emissionsData, baseYearData), 
      [emissionsData, baseYearData]),
    isDataLoaded: useCallback(() => initialized && !loading, [initialized, loading]),
    getTotalsByScope: useCallback(() => ({
      scope1: calculateTotals(emissionsData).scope1Total,
      scope2: calculateTotals(emissionsData).scope2Total,
      scope3: calculateTotals(emissionsData).scope3Total
    }), [emissionsData])
  };

  return (
    <UnifiedGHGContext.Provider value={contextValue}>
      {children}
    </UnifiedGHGContext.Provider>
  );
};

export default UnifiedGHGContext;
