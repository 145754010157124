// GHGBaseYearService.js
import { makeRequest, safeParseEmissions } from './GHGUtils';
import { fetchEmissionsData } from './GHGEmissionsService';

export const fetchBaseYear = async (currentEmissionsData, force = false) => {
  try {
    // Get base year data from backend
    const response = await makeRequest('/base-year');
    
    // The backend now returns both activeBaseYear and availableYears
    const { activeBaseYear, availableYears } = response;

    return { 
      baseYear: activeBaseYear, 
      availableYears: availableYears || [] 
    };
  } catch (error) {
    console.error('Error in fetchBaseYear:', error);
    throw error;
  }
};

export const setNewBaseYear = async (yearData) => {
  if (!yearData || !yearData.year) {
    throw new Error('Invalid year data provided');
  }

  try {
    const response = await makeRequest('/base-year', {
      method: 'POST',
      body: JSON.stringify({ year: yearData.year })
    });

    return response;
  } catch (error) {
    console.error('Error setting new base year:', error);
    throw error;
  }
};

export const getComparison = async (startDate, endDate) => {
  try {
    return await makeRequest('/base-year/comparison', {
      headers: {
        'startDate': startDate.toISOString(),
        'endDate': endDate.toISOString()
      }
    });
  } catch (error) {
    console.error('Error getting base year comparison:', error);
    throw error;
  }
};

// Add validation function for base year data
export const validateBaseYearData = (yearData) => {
  if (!yearData) return false;
  
  const requiredFields = ['year', 'scope1_total', 'scope2_total', 'scope3_total', 'total_emissions'];
  return requiredFields.every(field => {
    const value = yearData[field];
    return value !== undefined && value !== null && !isNaN(value);
  });
};
