// client/src/components/Settings/Scope3InitialAssessmentForm.js
import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Chip
} from "@mui/material";
import { styled } from '@mui/material/styles';

const SectionHeading = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 500,
  color: '#1F2937',
  marginBottom: '1rem',
  paddingBottom: '0.5rem',
  borderBottom: '1px solid #E5E7EB'
}));

const CenteredTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  padding: '8px 4px',
  '& .MuiButtonBase-root': {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const SCOPE3_CATEGORIES = [
  {
    id: 'purchased_goods',
    name: 'Purchased Goods & Services',
    description: 'Upstream emissions from purchased goods and services'
  },
  {
    id: 'capital_goods',
    name: 'Capital Goods',
    description: 'Upstream emissions from capital goods'
  },
  // Add all 15 scope 3 categories
];

const Scope3InitialAssessmentForm = () => {
  const [formData, setFormData] = useState({
    category: "",
    activityName: "",
    relevance: {
      isRelevant: "",
      justification: ""
    },
    sizeAssessment: {
      emissionScale: "",
      justification: ""
    },
    influenceAssessment: {
      level: "",
      justification: ""
    },
    dataAvailability: {
      status: "",
      details: ""
    },
    startDate: "",
    endDate: "",
    comments: ""
  });

  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.id || decodedToken.user.id);
        fetchAssessments();
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token");
      }
    }
  }, [token]);

  const fetchAssessments = async () => {
    try {
      const response = await fetch("http://localhost:5000/api/scope3-assessment", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch assessments');
      const data = await response.json();
      setAssessments(data.assessments);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      const [topLevelProp, nestedProp] = name.split('.');

      if (nestedProp) {
        return {
          ...prev,
          [topLevelProp]: {
            ...prev[topLevelProp],
            [nestedProp]: value
          }
        };
      } else {
        return {
          ...prev,
          [name]: value
        };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.startDate || !formData.endDate) {
      alert("Please provide both start and end dates.");
      return;
    }

    if (new Date(formData.startDate) > new Date(formData.endDate)) {
      alert("End Date must be after the Start Date.");
      return;
    }

    try {
      const response = await fetch("http://localhost:5000/api/scope3-assessment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          ...formData,
          relevance: {
            isRelevant: formData.relevance.isRelevant === "true",
            justification: formData.relevance.justification
          },
          sizeAssessment: {
            emissionScale: formData.sizeAssessment.emissionScale,
            justification: formData.sizeAssessment.justification
          }
        })
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const data = await response.json();
      setAssessments(prev => [...prev, data.assessment]);
      
      // Reset form
      setFormData({
        category: "",
        activityName: "",
        relevance: {
          isRelevant: "",
          justification: ""
        },
        sizeAssessment: {
          emissionScale: "",
          justification: ""
        },
        influenceAssessment: {
          level: "",
          justification: ""
        },
        dataAvailability: {
          status: "",
          details: ""
        },
        startDate: "",
        endDate: "",
        comments: ""
      });
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this assessment?")) {
      return;
    }

    try {
      const response = await fetch(`http://localhost:5000/api/scope3-assessment/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error("Failed to delete assessment");
      }

      setAssessments(prev => prev.filter(assessment => assessment._id !== id));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="scope3-container">
      <div className="content-container">
        <SectionHeading variant="h2">
          Scope 3 Initial Assessment
        </SectionHeading>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Date Range */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Start Date"
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="End Date"
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                size="small"
              />
            </Grid>

            {/* Category Selection */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Category</InputLabel>
                <Select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  required
                >
                  {SCOPE3_CATEGORIES.map(cat => (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Activity Name */}
            <Grid item xs={12} md={6}>
              <TextField
                label="Activity Name"
                name="activityName"
                value={formData.activityName}
                onChange={handleChange}
                fullWidth
                required
                size="small"
              />
            </Grid>

            {/* Relevance Assessment */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Relevance</InputLabel>
                <Select
                  name="relevance.isRelevant"
                  value={formData.relevance.isRelevant}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="">Select Relevance</MenuItem>
                  <MenuItem value="true">Relevant</MenuItem>
                  <MenuItem value="false">Not Relevant</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Size Assessment */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Size of Emissions</InputLabel>
                <Select
                  name="sizeAssessment.emissionScale"
                  value={formData.sizeAssessment.emissionScale}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="">Select Emission Scale</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="low">Low</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Comments */}
            <Grid item xs={12}>
              <TextField
                label="Comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#008080",
              color: "white",
              marginTop: 2,
              "&:hover": {
                backgroundColor: "#006666"
              }
            }}
          >
            Save Assessment
          </Button>
        </form>
      </div>

      {/* Assessments Table */}
      <div className="content-container">
        <SectionHeading variant="h2">
          Scope 3 Assessment Entries
        </SectionHeading>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <CenteredTableCell>Category</CenteredTableCell>
                <CenteredTableCell>Activity Name</CenteredTableCell>
                <CenteredTableCell>Relevance</CenteredTableCell>
                <CenteredTableCell>Size</CenteredTableCell>
                <CenteredTableCell>Actions</CenteredTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assessments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((assessment) => (
                  <TableRow key={assessment._id}>
                    <TableCell>{assessment.category}</TableCell>
                    <TableCell>{assessment.activityName}</TableCell>
                    <TableCell>
                      {assessment.relevance.isRelevant ? "Relevant" : "Not Relevant"}
                    </TableCell>
                    <TableCell>{assessment.sizeAssessment.emissionScale}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleDelete(assessment._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={assessments.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default Scope3InitialAssessmentForm;