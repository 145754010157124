// GHGContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { getInitialStates, handleAPIError } from './GHGContext/GHGUtils';
import { 
  fetchEmissionsData as fetchEmissionsDataService,
  updateTotalEmissions
} from './GHGContext/GHGEmissionsService';
import {
  fetchBaseYear as fetchBaseYearService,
  setNewBaseYear as setNewBaseYearService,
  getComparison as getComparisonService
} from './GHGContext/GHGBaseYearService';

const GHGContext = createContext();

export const GHGProvider = ({ children }) => {
  const navigate = useNavigate();
  
  // Base Year States
  const [baseYear, setBaseYear] = useState(null);
  const [baseYearComparison, setBaseYearComparison] = useState(null);
  const [allBaseYears, setAllBaseYears] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Get initial states
  const initialStates = getInitialStates();
  
  // Emissions States
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState(initialStates.dateRange);
  const [emissionsData, setEmissionsData] = useState(initialStates.emissionsData);
  const [totalEmissions, setTotalEmissions] = useState(initialStates.totalEmissions);

  // Check authentication status
  const checkAuth = useCallback(() => {
    const token = localStorage.getItem('token');
    const isValid = token !== null;
    setIsAuthenticated(isValid);
    return isValid;
  }, []);

  // Handle authentication errors
  const handleAuthError = useCallback(() => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/signin');
  }, [navigate]);

  // Fetch emissions data
  const fetchEmissionsData = async (skipAuthCheck = false) => {
    try {
      if (!skipAuthCheck && !checkAuth()) {
        return null;
      }

      setIsLoading(true);
      const { emissionsData: newData, totalEmissions: newTotals } = await fetchEmissionsDataService();
      setEmissionsData(newData);
      setTotalEmissions(newTotals);
      return newData;
    } catch (error) {
      const { requiresAuth } = handleAPIError(error);
      if (requiresAuth) {
        handleAuthError();
      }
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch base year data
  const fetchBaseYear = async (force = false) => {
    if (!checkAuth()) {
      return { baseYear: null, availableYears: [] };
    }

    try {
      setIsLoading(true);
      setError(null);

      const { baseYear: baseYearResult, availableYears } = await fetchBaseYearService(emissionsData, force);
      
      if (baseYearResult) {
        setBaseYear(baseYearResult);
      }
      setAllBaseYears(availableYears);

      return { baseYear: baseYearResult, availableYears };
    } catch (error) {
      const { requiresAuth } = handleAPIError(error);
      if (requiresAuth) {
        handleAuthError();
      }
      return { baseYear: null, availableYears: [] };
    } finally {
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  };

  // Set new base year
  const setNewBaseYear = async (yearData) => {
    if (!checkAuth()) return null;

    try {
      setIsLoading(true);
      setError(null);

      const response = await setNewBaseYearService(yearData);
      
      if (response) {
        setBaseYear(response);
        const { availableYears } = await fetchBaseYear(true);
        setAllBaseYears(availableYears);
      }

      return response;
    } catch (error) {
      const { requiresAuth } = handleAPIError(error);
      if (requiresAuth) {
        handleAuthError();
      }
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Get comparison
  const getComparison = useCallback(async (startDate, endDate) => {
    if (!baseYear || !checkAuth()) return null;

    try {
      const response = await getComparisonService(startDate, endDate);
      setBaseYearComparison(response);
      return response;
    } catch (error) {
      const { requiresAuth } = handleAPIError(error);
      if (requiresAuth) {
        handleAuthError();
      }
      return null;
    }
  }, [baseYear, checkAuth, handleAuthError]);

  // Refresh all data
  const refreshData = async () => {
    if (!checkAuth()) return null;

    try {
      setIsLoading(true);
      setError(null);

      const newEmissionsData = await fetchEmissionsData(true);
      const { baseYear: baseYearResult, availableYears } = await fetchBaseYear(true);

      if (baseYearResult) {
        setBaseYear(baseYearResult);
      }
      setAllBaseYears(availableYears);

      return {
        baseYear: baseYearResult,
        allBaseYears: availableYears,
        emissionsData: newEmissionsData
      };
    } catch (error) {
      const { requiresAuth } = handleAPIError(error);
      if (requiresAuth) {
        handleAuthError();
      }
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to update total emissions when emissions data changes
  useEffect(() => {
    const newTotals = updateTotalEmissions(emissionsData);
    setTotalEmissions(newTotals);
  }, [emissionsData]);

  // Effect to check authentication and load initial data
  useEffect(() => {
    const loadInitialData = async () => {
      if (isInitialLoad && checkAuth()) {
        await fetchBaseYear();
      }
    };

    loadInitialData();
  }, [isInitialLoad, checkAuth]);

  // Effect to handle authentication changes
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  // Context value
  const contextValue = {
    baseYear,
    baseYearComparison,
    allBaseYears,
    setNewBaseYear,
    getComparison,
    emissionsData,
    totalEmissions,
    dateRange,
    setDateRange,
    isLoading,
    error,
    refreshData,
    fetchBaseYear,
    fetchEmissionsData,
    isAuthenticated
  };

  return (
    <GHGContext.Provider value={contextValue}>
      {children}
    </GHGContext.Provider>
  );
};

export const useGHG = () => {
  const context = useContext(GHGContext);
  if (!context) {
    throw new Error('useGHG must be used within a GHGProvider');
  }
  return context;
};

export default GHGContext;