// client/src/pages/Settings/Scope3InitialAssessmentSettingsPage.js
import React from 'react';
import Scope3InitialAssessmentForm from '../../components/Settings/Scope3InitialAssessmentForm';

const Scope3InitialAssessmentSettingsPage = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Scope 3 Initial Assessment</h1>
      <p className="text-gray-600 mb-6">
        Assess and prioritize your Scope 3 emission categories to determine 
        which are relevant for your organization.
      </p>
      <Scope3InitialAssessmentForm />
    </div>
  );
};

export default Scope3InitialAssessmentSettingsPage;