// Constants and initial states
export const API_BASE_URL = process.env.REACT_APP_API_URL + '/api';
export const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
export const MIN_REFRESH_INTERVAL = 2000; // 2 seconds
export const MAX_RETRIES = 3;
export const RETRY_DELAY = 1000; // 1 second

export const ENDPOINTS = {
  scope1: {
    stationaryCombustion: '/scope1/stationary-combustion',
    mobileCombustion: '/scope1/mobile-combustion',
    fugitiveEmissions: '/scope1/fugitive-emissions'
  },
  scope2: {
    purchasedElectricity: '/scope2/purchased-electricity',
    purchasedHeating: '/scope2/purchased-heating'
  },
  scope3: {
    businessTravelLandDB: '/scope3/business-travel-land-db',
    employeeCommutingLandDB: '/scope3/employee-commuting-land-db',
    upstreamLeasedAssets: '/scope3/upstream-leased-assets-AS-S1-SC'
  }
};

const createEmptyCategory = () => ({
  data: [],
  lastUpdated: null,
  loading: false,
  error: null
});

export const initialEmissionsData = {
  scope1: {
    stationaryCombustion: createEmptyCategory(),
    mobileCombustion: createEmptyCategory(),
    fugitiveEmissions: createEmptyCategory()
  },
  scope2: {
    purchasedElectricity: createEmptyCategory(),
    purchasedHeating: createEmptyCategory()
  },
  scope3: {
    businessTravelLandDB: createEmptyCategory(),
    employeeCommutingLandDB: createEmptyCategory(),
    upstreamLeasedAssets: createEmptyCategory()
  }
};

export const initialBaseYearData = {
  current: {
    scope1_total: 0,
    scope2_total: 0,
    scope3_total: 0,
    totalEmissions: 0,
    year: "2020",
    change: {
      value: "0.0",
      isIncrease: false
    }
  },
  loading: false,
  error: null
};

export const getInitialDateRange = () => {
  const end = new Date();
  const start = new Date();
  start.setFullYear(start.getFullYear() - 1);
  return { start, end };
};
